import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../data/urls";
import Spinner from "../../components/Spinner";

const ReportsList = () => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    const fetchReports = async () => {
      try {
        setLoading(true);
        const authToken = localStorage.getItem("token");
        const response = await fetch(
          `${BACKEND_URL}/reports/?skip=0&limit=100`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch reports.");
        }

        const data = await response.json();
        setReports(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p className="text-center text-red-500">Error: {error}</p>;
  }

  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-2xl font-bold mb-4">Reports</h1>
      {reports.length === 0 ? (
        <p className="text-center">No reports found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 px-4 py-2 text-left">
                  ID
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Company Domain
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  User Name
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Technical Score
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Non-Technical Score
                </th>
                <th className="border border-gray-300 px-4 py-2 text-left">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report) => (
                <tr
                  key={report.id}
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={() => navigate(`/reports/${report.id}`)} // Navigate on click
                >
                  <td className="border border-gray-300 px-4 py-2">
                    {report.id}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {report.company.domain}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {report.user.email}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {Math.round(report.technical_score) || "Incomplete"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {Math.round(report.non_technical_score) || "Incomplete"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {new Date(report.created_at).toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReportsList;
