import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import * as ReactDOM from "react-dom";

export const generatePDF = async (props) => {
  const container = document.createElement("div");
  container.style.position = "absolute";
  container.style.left = "-9999px";
  container.style.width = "1000px"; // Changed to 800px
  container.style.backgroundColor = "white";
  document.body.appendChild(container);

  try {
    const DashboardA = (await import("../pages/dashboard/dashboard_A")).default;
    const root = document.createElement("div");
    root.style.width = "100%";
    root.style.padding = "10px"; // Reduced padding
    root.style.boxSizing = "border-box";

    // Force all threats to be expanded
    const expandedThreats = Object.entries(props.threats)
      .flatMap(([_, groupData]) => Object.keys(groupData.threats))
      .reduce((acc, threatName) => ({ ...acc, [threatName]: true }), {});

    await new Promise((resolve) => {
      ReactDOM.default.render(
        <div style={{ width: "100%", margin: "0 auto" }}>
          <DashboardA {...props} initialOpenThreats={expandedThreats} />
        </div>,
        root,
        resolve
      );
    });

    container.appendChild(root);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const canvas = await html2canvas(root, {
      scale: 2,
      useCORS: true,
      logging: false,
      backgroundColor: "#ffffff",
      width: 1000, // Changed to 800
      windowWidth: 1000, // Changed to 800
    });

    const pdf = new jsPDF({
      unit: "pt",
      format: "a4",
      orientation: "portrait",
    });

    // Calculate dimensions to fit A4 width exactly
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    // Add pages with minimal margins
    const totalPages = Math.ceil(
      ((canvas.height / canvas.width) * pageWidth) / pageHeight
    );

    for (let page = 0; page < totalPages; page++) {
      if (page > 0) pdf.addPage();

      pdf.addImage(
        canvas.toDataURL("image/jpeg", 1.0),
        "JPEG",
        0, // No left margin
        -(page * pageHeight),
        pageWidth, // Full width
        (canvas.height * pageWidth) / canvas.width // Maintain aspect ratio
      );
    }

    pdf.save("security-report.pdf");
  } finally {
    ReactDOM.default.unmountComponentAtNode(container);
    document.body.removeChild(container);
  }
};
