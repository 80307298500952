import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div
        className="w-16 h-16 border-4 rounded-full animate-spin"
        style={{
          borderColor: "rgb(79 70 229 / 0.2)",
          borderTopColor: "rgb(79 70 229)",
        }}
      ></div>
    </div>
  );
};

export default LoadingSpinner;
