import { ShieldAlert } from "lucide-react";

export default function DesignC({
  companyName,
  technicalScore,
  nonTechnicalScore,
  overallScore,
  technicalResults,
  nonTechnicalResults,
  aiResults,
  threats,
}) {
  const getScorePercentage = (score, weight) => {
    return Math.round((score / weight) * 100);
  };

  return (
    <div className="space-y-6 min-h-screen pb-32">
      <div className="text-center">
        <h1 className="text-3xl m-10 font-bold text-gray-900">
          {companyName || "Company Domain"}
        </h1>
      </div>

      {/* Score Cards - Same as Design A */}
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center">
          {/* Technical Score Card */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4">Technical Score</h2>
            <div className="relative">
              <svg
                className="transform -rotate-90 w-28 h-28"
                viewBox="0 0 100 100"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="rgb(210, 210, 210)"
                  strokeWidth="10"
                  fill="none"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke={
                    getScorePercentage(technicalScore, 10) >= 70
                      ? "rgb(75, 160, 60)"
                      : getScorePercentage(technicalScore, 10) >= 50
                      ? "rgb(230, 190, 70)"
                      : "rgb(190, 60, 60)"
                  }
                  strokeWidth="10"
                  fill="none"
                  strokeDasharray="283"
                  strokeDashoffset={
                    283 - (283 * getScorePercentage(technicalScore, 10)) / 100
                  }
                  strokeLinecap="round"
                  style={{ transition: "stroke-dashoffset 2s ease-in-out" }}
                />
              </svg>
              <div className="absolute inset-0 flex justify-center items-center">
                <span className="text-2xl font-bold">
                  {getScorePercentage(technicalScore, 10)}%
                </span>
              </div>
            </div>
          </div>

          {/* Overall Score Card */}
          <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center">
            <h2 className="text-2xl font-semibold mb-4">
              Overall Security Score
            </h2>
            <div className="relative">
              <svg
                className="transform -rotate-90 w-40 h-40"
                viewBox="0 0 100 100"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="rgb(210, 210, 210)"
                  strokeWidth="10"
                  fill="none"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke={
                    overallScore >= 70
                      ? "rgb(75, 160, 60)"
                      : overallScore >= 50
                      ? "rgb(230, 190, 70)"
                      : "rgb(190, 60, 60)"
                  }
                  strokeWidth="10"
                  fill="none"
                  strokeDasharray="283"
                  strokeDashoffset={283 - (283 * overallScore) / 100}
                  strokeLinecap="round"
                  style={{ transition: "stroke-dashoffset 2s ease-in-out" }}
                />
              </svg>
              <div className="absolute inset-0 flex justify-center items-center">
                <span className="text-3xl font-bold">{overallScore}%</span>
              </div>
            </div>
          </div>

          {/* Non-Technical Score Card */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4">Non-Technical Score</h2>
            <div className="relative">
              <svg
                className="transform -rotate-90 w-28 h-28"
                viewBox="0 0 100 100"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke="rgb(210, 210, 210)"
                  strokeWidth="10"
                  fill="none"
                />
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  stroke={
                    getScorePercentage(nonTechnicalScore, 90) >= 70
                      ? "rgb(75, 160, 60)"
                      : getScorePercentage(nonTechnicalScore, 90) >= 50
                      ? "rgb(230, 190, 70)"
                      : "rgb(190, 60, 60)"
                  }
                  strokeWidth="10"
                  fill="none"
                  strokeDasharray="283"
                  strokeDashoffset={
                    283 -
                    (283 * getScorePercentage(nonTechnicalScore, 90)) / 100
                  }
                  strokeLinecap="round"
                  style={{ transition: "stroke-dashoffset 2s ease-in-out" }}
                />
              </svg>
              <div className="absolute inset-0 flex justify-center items-center">
                <span className="text-2xl font-bold">
                  {getScorePercentage(nonTechnicalScore, 90)}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Threat Group Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-20">
        {Object.entries(threats).map(([groupName, groupData]) => (
          <div
            key={groupName}
            className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow flex flex-col"
          >
            {/* Title on top */}
            <h2 className="text-2xl font-bold mb-4">{groupName}</h2>

            {/* 1st Row: Logo + Risk Rating */}
            <div className="flex flex-col md:flex-row items-center md:w-full mb-4">
              <div className="flex flex-col items-center md:w-1/3 mb-4 md:mb-0">
                <ShieldAlert
                  className={`w-24 h-24 mb-2 ${
                    groupData.probability >= 0.7
                      ? "text-[rgb(190,60,60)]"
                      : groupData.probability >= 0.4
                      ? "text-[rgb(230,190,70)]"
                      : "text-[rgb(75,160,60)]"
                  }`}
                />
                <div className="text-lg font-semibold text-center mb-4">
                  Risk Level: {Math.round(groupData.probability * 100)}%
                </div>
              </div>

              {/* 2nd Column: Summary */}
              <div className="text-sm text-gray-600 text-center md:text-left md:w-2/3 prose prose-sm">
                <div className="text-gray-600 whitespace-pre-wrap">
                  {groupData.summary}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
