import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FaArrowRight } from "react-icons/fa";

const InputForm = ({ onSubmit }) => {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    domain: Yup.string()
      .required("Domain is required.")
      .matches(
        /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid domain name."
      ),
    // industry: Yup.string().required("Please select an industry."),
  });

  return (
    <div className="h-[calc(100vh-6rem)] flex flex-col items-center justify-center -mt-32 animate-fade-in-up delay-250">
      <div className="w-full max-w-4xl px-4 space-y-6 animate-fade-in-up">
        <div className="text-center space-y-4 animate-fade-in-down">
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Please enter your website's address to start the assessment.
          </p>
        </div>

        <Formik
          initialValues={{ domain: "" }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values);
            resetForm();
          }}
        >
          {({ isSubmitting }) => (
            <div className="mt-4">
              <Form className="relative">
                <Field
                  id="domain"
                  name="domain"
                  type="text"
                  className="pt-4 pe-40 pb-4 ps-6 w-full h-[75px] outline-none text-black rounded-full bg-white shadow-lg [font-size:25pt] border border-gray-100 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 transition-all"
                  placeholder="e.g., example.com"
                />
                <ErrorMessage
                  name="domain"
                  component="p"
                  className="text-red-500 text-base mt-3 text-center absolute"
                />
                <button
                  type="submit"
                  className="py-2 px-5 inline-flex items-center font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[71px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}{" "}
                  <FaArrowRight className="ms-2 text-[10px]" />
                </button>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default InputForm;
