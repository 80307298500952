import { BACKEND_URL } from "../data/urls";

export class ReportService {
  constructor(baseUrl = BACKEND_URL) {
    this.baseUrl = baseUrl;
  }

  _getHeaders() {
    const token = localStorage.getItem("token");
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  _handleAuthError(response) {
    if (response.status === 401 || response.status === 400) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
  }

  _formatQuestions(radioData, textData) {
    const formattedRadioQuestions = radioData.data.map((q) => ({
      id: q.id,
      label: q.desc,
      section_id: q.section_id,
      name: q.name,
      type: "Yes/No",
    }));

    const formattedTextQuestions = textData.data.map((q) => ({
      id: q.id,
      label: q.desc,
      section_id: q.section_id,
      name: q.name,
      type: "Text",
    }));

    return [...formattedRadioQuestions, ...formattedTextQuestions];
  }

  async createReport(data) {
    const response = await fetch(`${this.baseUrl}/companies/create-report`, {
      method: "POST",
      headers: this._getHeaders(),
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      this._handleAuthError(response);
      throw new Error("Failed to create report.");
    }

    return await response.json();
  }

  async checkTechnicalScore(reportId) {
    const response = await fetch(
      `${this.baseUrl}/companies/check-technical-score`,
      {
        method: "POST",
        headers: this._getHeaders(),
        body: JSON.stringify({ report_id: reportId }),
      }
    );

    if (!response.ok) {
      this._handleAuthError(response);
      throw new Error("Failed to check technical score.");
    }

    return await response.json();
  }

  async checkQuestionsScore(answers, reportId) {
    const response = await fetch(
      `${this.baseUrl}/companies/check-questions-score`,
      {
        method: "POST",
        headers: this._getHeaders(),
        body: JSON.stringify({
          answers,
          report_id: reportId,
        }),
      }
    );

    if (!response.ok) {
      this._handleAuthError(response);
      throw new Error("Failed to check non-technical score.");
    }

    return await response.json();
  }

  async fetchQuestions() {
    const [radioResponse, textResponse] = await Promise.all([
      fetch(`${this.baseUrl}/checks/?skip=0&limit=100&section=Non-Technical`, {
        headers: this._getHeaders(),
      }),
      fetch(`${this.baseUrl}/checks/?skip=0&limit=100&section=AI`, {
        headers: this._getHeaders(),
      }),
    ]);

    if (!radioResponse.ok || !textResponse.ok) {
      throw new Error("Failed to fetch questions");
    }

    const radioData = await radioResponse.json();
    const textData = await textResponse.json();

    return this._formatQuestions(radioData, textData);
  }
}

// Create a singleton instance
export const reportService = new ReportService();
