import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Navbar from "../../component/Navbar/navbar";
import InputForm from "./company_input";
import Questions from "./questions";
import Spinner from "../../components/Spinner";
import { reportService } from "../../services/reportService";

export default function Demo() {
  const [currentPageIndex, setCurrentPageIndex] = useState(0); // Track current page
  const [companyData, setCompanyData] = useState({}); // Collect data from forms
  const [questionData, setQuestionData] = useState({}); // Collect data from forms
  const [technicalLoading, setTechLoading] = useState(true); // Loading state for API calls
  const [nonTechnicalLoading, setNonTechLoading] = useState(true); // Loading state for API calls
  const [reportId, setReportId] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // Add new state for animation
  const [questions, setQuestions] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [questionsError, setQuestionsError] = useState(null);

  const navigate = useNavigate(); // Initialize navigate function

  useEffect(() => {
    // Navigate only when both technicalLoading and nonTechLoading are false
    if (!technicalLoading && !nonTechnicalLoading && reportId) {
      navigate(`/reports/${reportId}`);
    }
  }, [technicalLoading, nonTechnicalLoading, reportId, navigate]);

  // Add useEffect for fetching questions
  useEffect(() => {
    const loadQuestions = async () => {
      try {
        const questions = await reportService.fetchQuestions();
        setQuestions(questions);
      } catch (error) {
        setQuestionsError(error.message);
      } finally {
        setQuestionsLoading(false);
      }
    };

    loadQuestions();
  }, []);

  // Handle form submissions
  const handleCompanyFormSubmit = async (data) => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentPageIndex(1);
      setIsAnimating(false);
    }, 300); // Match this with CSS transition duration

    setCompanyData({ ...companyData, ...data });

    try {
      setTechLoading(true);
      const report = await reportService.createReport(data);
      setReportId(report.report_id);
      await reportService.checkTechnicalScore(report.report_id);
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setTechLoading(false);
    }
  };

  const handleQuestionsFormSubmit = async (data) => {
    setSubmitting(true);
    setQuestionData({ ...questionData, ...data });
    try {
      setNonTechLoading(true);
      await reportService.checkQuestionsScore(data, reportId);
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setNonTechLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="pt-[74px]">
        <section className="relative min-h-[calc(100vh-74px)] w-full py-20 overflow-hidden bg-center bg-no-repeat bg-cover">
          {isSubmitting ? (
            <div className="fixed inset-0 top-[74px] flex flex-col items-center justify-center bg-gradient-to-b to-transparent from-indigo-600/20">
              <div className="text-lg font-medium text-gray-600 mb-4">
                Performing Checks...
              </div>
              <Spinner />
            </div>
          ) : (
            <div className="bg-gradient-to-b to-transparent from-indigo-600/20">
              <div
                className={`transition-opacity duration-300 ${
                  isAnimating ? "opacity-0" : "opacity-100"
                }`}
              >
                {currentPageIndex === 0 ? (
                  <InputForm
                    key="input-form"
                    onSubmit={handleCompanyFormSubmit}
                  />
                ) : (
                  <Questions
                    key="questions"
                    questions={questions}
                    isLoading={questionsLoading}
                    error={questionsError}
                    onSubmit={handleQuestionsFormSubmit}
                    isSubmitting={isSubmitting}
                  />
                )}
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
}
