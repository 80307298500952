import { useState, useEffect } from "react";
import CountUp from "react-countup";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { AlertTriangle, CheckCircle, XCircle, ShieldAlert } from "lucide-react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import Chevron icons from react-icons

export default function DesignA({
  companyName,
  technicalScore,
  nonTechnicalScore,
  overallScore,
  technicalResults,
  nonTechnicalResults,
  aiResults,
  threats,
  initialOpenThreats = {}, // Add this prop with default empty object
}) {
  const [animate, setAnimate] = useState(false);
  const [openThreats, setOpenThreats] = useState(initialOpenThreats);

  useEffect(() => {
    // Small delay to ensure DOM is ready
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const toggleThreat = (key) => {
    setOpenThreats((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  const getStatusFromScore = (score, success_criteria) => {
    if (score === success_criteria) return "passed";
    if (score !== success_criteria) return "failed";
    return "failed";
  };

  const getScorePercentage = (score, weight) => {
    return Math.round((score / weight) * 100);
  };

  const formatTechnicalChecks = () => {
    return technicalResults.map((result) => ({
      name: result.check.name,
      display_name: result.check.display_name,
      status: getStatusFromScore(
        result.result.raw_result,
        result.success_criteria_list[0].success_criteria
      ),
      description: result.check.desc,
    }));
  };

  const formatNonTechnicalChecks = () => {
    const non_technical_map = nonTechnicalResults.map((result) => ({
      question: result.check.desc,
      answer: result.result.raw_result === 1 ? "Yes" : "No",
      status:
        result.result.raw_result ===
        result.success_criteria_list[0].success_criteria
          ? "high"
          : "low",
    }));
    const ai_map = aiResults.map((result) => ({
      question: result.check.desc,
      answer: result.result.ai_answer,
      status: result.result.cr_value,
    }));
    return [...non_technical_map, ...ai_map];
  };
  // Move the existing return JSX here
  return (
    <div className="space-y-6">
      <div className="text-center">
        <h1 className="text-3xl m-10 font-bold text-gray-900">
          {companyName || "Company Domain"}
        </h1>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-center">
          {/* Card 2 */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4">Technical Score</h2>
            <div className="relative w-28 h-28">
              <CircularProgressbar
                value={animate ? getScorePercentage(technicalScore, 10) : 0}
                text="" // Remove text prop and use overlay
                styles={buildStyles({
                  rotation: 0,
                  strokeLinecap: "round",
                  textSize: "16px",
                  pathTransitionDuration: 2,
                  pathColor:
                    getScorePercentage(technicalScore, 10) >= 70
                      ? "rgb(75, 160, 60)"
                      : getScorePercentage(technicalScore, 10) >= 50
                      ? "rgb(230, 190, 70)"
                      : "rgb(190, 60, 60)",
                  trailColor: "rgb(210, 210, 210)",
                  pathTransition: "stroke-dashoffset 2s ease 0s",
                })}
              />
              {/* Overlay for centered text */}
              <div className="absolute inset-0 flex items-center justify-center">
                <CountUp
                  end={getScorePercentage(technicalScore, 10)}
                  duration={2}
                  suffix="%"
                  className="text-2xl font-bold"
                />
              </div>
            </div>
          </div>

          {/* Card 1 - Centered and Larger */}
          <div className="bg-white rounded-lg shadow-lg p-8 flex flex-col items-center">
            <h2 className="text-2xl font-semibold mb-4">
              Overall Security Score
            </h2>
            <div className="relative w-40 h-40">
              <CircularProgressbar
                value={animate ? overallScore : 0}
                text=""
                styles={buildStyles({
                  rotation: 0, // Changed to start from top
                  strokeLinecap: "round",
                  textSize: "16px",
                  pathTransitionDuration: 2,
                  pathColor:
                    overallScore >= 70
                      ? "rgb(75, 160, 60)"
                      : overallScore >= 50
                      ? "rgb(230, 190, 70)"
                      : "rgb(190, 60, 60)",
                  trailColor: "rgb(210, 210, 210)",
                  pathTransition: "stroke-dashoffset 2s ease 0s",
                })}
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <CountUp
                  end={overallScore}
                  duration={2}
                  suffix="%"
                  className="text-3xl font-bold"
                />
              </div>
            </div>
          </div>

          {/* Card 3 */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
            <h2 className="text-xl font-semibold mb-4">Non-Technical Score</h2>
            <div className="relative w-28 h-28">
              <CircularProgressbar
                value={animate ? getScorePercentage(nonTechnicalScore, 90) : 0}
                text=""
                styles={buildStyles({
                  rotation: 0,
                  strokeLinecap: "round",
                  textSize: "16px",
                  pathTransitionDuration: 2,
                  pathColor:
                    getScorePercentage(nonTechnicalScore, 90) >= 70
                      ? "rgb(75, 160, 60)"
                      : getScorePercentage(nonTechnicalScore, 90) >= 50
                      ? "rgb(230, 190, 70)"
                      : "rgb(190, 60, 60)",
                  textColor: "#111827",
                  trailColor: "rgb(210, 210, 210)",
                  pathTransition: "stroke-dashoffset 2s ease 0s",
                })}
              />
              <div className="absolute inset-0 flex items-center justify-center">
                <CountUp
                  end={getScorePercentage(nonTechnicalScore, 90)}
                  duration={2}
                  suffix="%"
                  className="text-2xl font-bold"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        {/* Technical Checks Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="mb-4">
            <h2 className="text-2xl font-semibold">
              Technical Security Checks
            </h2>
            {/* <p className="text-gray-600">
                Score: {Math.round(technicalScore)} / 10
              </p> */}
          </div>
          <div className="space-y-4">
            {formatTechnicalChecks().map((check) => (
              <div
                key={check.name}
                className="flex items-center justify-between"
              >
                <div>
                  <p className="font-medium">{check.display_name}</p>
                  <p className="text-sm text-gray-600">{check.description}</p>
                </div>
                <StatusBadge status={check.status} />
              </div>
            ))}
          </div>
        </div>

        {/* Non-Technical Assessment Card */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="mb-4">
            <h2 className="text-2xl font-semibold">Non-Technical Assessment</h2>
            {/* <p className="text-gray-600">
                Score: {Math.round(nonTechnicalScore)} / 90
              </p> */}
          </div>
          <div className="space-y-4">
            {formatNonTechnicalChecks().map((item) => (
              <div key={item.question} className="space-y-1">
                <p className="font-medium">{item.question}</p>
                <div className="flex items-center justify-between">
                  <p className="text-sm">{item.answer}</p>
                  <QuestionStatusBadge status={item.status} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Cyber Attacks Card */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="mb-4">
          <h2 className="text-2xl font-semibold">Most Likely Cyber Threats</h2>
          <p className="text-gray-600">Based on your security profile</p>
        </div>
        <div className="space-y-4">
          {Object.entries(threats)
            .flatMap(([_, groupData]) =>
              Object.entries(groupData.threats).map(
                ([threatName, threatData]) => ({
                  name: threatName,
                  probability: threatData.probability,
                  failed_checks: threatData.failed_checks,
                  successful_checks: threatData.successful_checks,
                })
              )
            )
            .sort((a, b) => b.probability - a.probability)
            .map(
              ({
                name: threatName,
                probability,
                failed_checks,
                successful_checks,
              }) => (
                <div key={threatName} className="border rounded-lg p-4">
                  <div
                    className="flex items-center justify-between cursor-pointer"
                    onClick={() => toggleThreat(threatName)}
                  >
                    <div className="flex items-center space-x-2">
                      <ShieldAlert
                        className={`w-5 h-5 ${
                          probability >= 0.5
                            ? "text-[rgb(190,60,60)]"
                            : probability >= 0.3
                            ? "text-[rgb(230,190,70)]"
                            : "text-[rgb(75,160,60)]"
                        }`}
                      />
                      <div>
                        <span className="font-medium">{threatName}</span>
                        {/* <span className="ml-2 text-sm text-gray-500">
                            ({Math.round(probability * 100)}% risk)
                          </span> */}
                      </div>
                    </div>
                    {openThreats[threatName] ? (
                      <FaChevronUp className="w-4 h-4 text-gray-500" />
                    ) : (
                      <FaChevronDown className="w-4 h-4 text-gray-500" />
                    )}
                  </div>

                  {openThreats[threatName] && (
                    <div className="mt-4 space-y-4">
                      {failed_checks.length > 0 && (
                        <div>
                          <h4 className="text-sm font-semibold text-red-600 mb-2">
                            Failed Checks:
                          </h4>
                          <ul className="list-none text-sm text-gray-600 space-y-1">
                            {failed_checks.map((check) => (
                              <li key={check.id} className="flex">
                                <span className="mr-2">•</span>
                                <span>
                                  <span className="font-medium">
                                    {check.display_name || check.name}
                                  </span>
                                  : {check.desc}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}

                      {successful_checks.length > 0 && (
                        <div>
                          <h4 className="text-sm font-semibold text-green-600 mb-2">
                            Passed Checks:
                          </h4>
                          <ul className="list-none text-sm text-gray-600 space-y-1">
                            {successful_checks.map((check) => (
                              <li key={check.id} className="flex">
                                <span className="mr-2">•</span>
                                <span>
                                  <span className="font-medium">
                                    {check.display_name || check.name}
                                  </span>
                                  : {check.desc}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
}

function StatusBadge({ status }) {
  const colors = {
    passed: { text: "#166534" },
    failed: { text: "#991b1b" },
    warning: { text: "#854d0e" },
  };

  const icons = {
    passed: "✓",
    failed: "✕",
    warning: "⚠",
  };

  const style = colors[status] || colors.warning;

  return (
    <span style={{ color: style.text, fontSize: "12px", fontWeight: "500" }}>
      {icons[status]} {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
}

function QuestionStatusBadge({ status }) {
  const colors = {
    high: { text: "#166534" },
    medium: { text: "#854d0e" },
    low: { text: "#991b1b" },
  };

  const style = colors[status] || colors.medium;

  return (
    <span style={{ color: style.text, fontSize: "12px", fontWeight: "500" }}>
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </span>
  );
}
