import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { BACKEND_URL } from "../data/urls";
import Spinner from "./Spinner";
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/login/test-token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setIsAuthenticated(true); // Token is valid
        } else {
          setIsAuthenticated(false); // Token is invalid or expired
        }
      } catch (error) {
        console.error("Error checking token validity:", error);
        setIsAuthenticated(false); // Treat errors as invalid token
      } finally {
        setIsLoading(false); // Stop loading once the check is done
      }
    };

    if (token) {
      checkTokenValidity(); // Only check token if it exists
    } else {
      setIsLoading(false);
      setIsAuthenticated(false); // No token, not authenticated
    }
  }, [token]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
